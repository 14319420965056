.containerOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.suggestion {
  width: 100%;
  overflow: hidden;
}
.suggestionsList {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style-type: none;
  border: 1px solid #d7d7d7;
  border-top: 0px;
  background-color: #fff;
  font-family: 'Suisse Intl Mono Regular', monospace;
  font-weight: 300;
  font-size: 14px;
  border-radius: 8px;
  z-index: 1000;
  overflow: auto;
  margin: 1rem 0 0;
  top: 70px;

  &::after {
    background-image: url('/static/location/google.png');
    content: '';
    width: 100%;
    display: block;
    border-top: 1px solid #d7d7d7;
    height: 3rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12rem;
  }
}
.suggestionFocused {
  background-color: #f4f4f4;
}
.container {
  position: relative;
  width: 100%;

  input {
    border-radius: 0;
  }

  &::before {
    content: '';
    position: absolute;
    background: center no-repeat url('/static/icons/search-new.svg');
    top: -1px;
    left: 0;
    box-sizing: border-box;
    height: 100%;
    width: 40px;
  }

  & > li {
    width: 100%;
    overflow: hidden;
    & > div {
      width: 100%;
      overflow: hidden;
    }
  }
  /* When adding flexbox to fields, this is so that the container for the address field will fill the size of the parent container. */
  @media (max-width: 767px) {
    width: 100%;
  }
}
